import * as Sentry from "@sentry/browser";

const sentryDsn = window.appConfig["sentryDsn"];

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    tracesSampleRate: 1.0,
  });
}

window.testSentryIsWorking = function () {
  x / 4;
};

window.Sentry = Sentry;
